var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.verify-truewallet-account-register",modifiers:{"verify-truewallet-account-register":true}}],attrs:{"size":"sm","variant":_vm.isSuccess ? 'success' : 'outline-danger'}},[_vm._v(" "+_vm._s(_vm.isSuccess ? 'Verified' : ' Verify account')+" ")]),_c('b-modal',{attrs:{"id":"verify-truewallet-account-register","hide-footer":"","size":"xl","title":"Register true wallet account."}},[_c('validation-observer',{ref:"registerForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onRegister.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"pin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Pin"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"type":"text"},model:{value:(_vm.trueWalletRegisterForm.pin),callback:function ($$v) {_vm.$set(_vm.trueWalletRegisterForm, "pin", $$v)},expression:"trueWalletRegisterForm.pin"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"keyId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Key ID"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"type":"text"},model:{value:(_vm.trueWalletRegisterForm.keyId),callback:function ($$v) {_vm.$set(_vm.trueWalletRegisterForm, "keyId", $$v)},expression:"trueWalletRegisterForm.keyId"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"loginToken","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Login Token"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"type":"text"},model:{value:(_vm.trueWalletRegisterForm.loginToken),callback:function ($$v) {_vm.$set(_vm.trueWalletRegisterForm, "loginToken", $$v)},expression:"trueWalletRegisterForm.loginToken"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"tmnId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tmn ID"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"type":"text"},model:{value:(_vm.trueWalletRegisterForm.tmnId),callback:function ($$v) {_vm.$set(_vm.trueWalletRegisterForm, "tmnId", $$v)},expression:"trueWalletRegisterForm.tmnId"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-button',{attrs:{"variant":"success","type":"submit","disabled":_vm.isRegisteringTrueWallet}},[_vm._v(" "+_vm._s(_vm.$t('buttons.submit'))+" ")])],1)],1)],1),_c('b-modal',{attrs:{"hide-header":"","hide-footer":"","no-close-on-backdrop":"","no-close-on-esc":"","size":"xl"},model:{value:(_vm.isShowOTPModal),callback:function ($$v) {_vm.isShowOTPModal=$$v},expression:"isShowOTPModal"}},[_c('validation-observer',{ref:"verifyForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onVerifyOTP.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"reference","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Reference code"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"placeholder":"","type":"text","readonly":"readonly"},model:{value:(_vm.trueWalletVerifyOTPForm.reference),callback:function ($$v) {_vm.$set(_vm.trueWalletVerifyOTPForm, "reference", $$v)},expression:"trueWalletVerifyOTPForm.reference"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"otp","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"OTP"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"placeholder":"","type":"text"},model:{value:(_vm.trueWalletVerifyOTPForm.otp),callback:function ($$v) {_vm.$set(_vm.trueWalletVerifyOTPForm, "otp", _vm._n($$v))},expression:"trueWalletVerifyOTPForm.otp"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-button',{attrs:{"variant":"success","type":"submit","disabled":_vm.isVerifyingTrueWallet || _vm.isRegisteringTrueWallet}},[_vm._v(" "+_vm._s(_vm.$t('buttons.submit'))+" ")]),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"outline-primary","type":"button","disabled":_vm.isVerifyingTrueWallet || _vm.isRegisteringTrueWallet},on:{"click":_vm.onRegister}},[_vm._v(" ส่งอีกครั้ง ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }