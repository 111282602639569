<template>
  <div>
    <b-button
      v-b-modal.verify-truewallet-account-register
      size="sm"
      :variant="isSuccess ? 'success' : 'outline-danger'"
    >
      {{ isSuccess ? 'Verified' : ' Verify account' }}
    </b-button>

    <b-modal
      id="verify-truewallet-account-register"
      hide-footer
      size="xl"
      title="Register true wallet account."
    >
      <validation-observer ref="registerForm">
        <b-form @submit.prevent="onRegister">
          <validation-provider
            v-slot="{ errors }"
            name="pin"
            rules="required"
          >
            <b-form-group label="Pin">
              <b-form-input
                v-model="trueWalletRegisterForm.pin"
                :state="errors[0] ? false : null"
                :aria-invalid="false"
                type="text"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            name="keyId"
            rules="required"
          >
            <b-form-group label="Key ID">
              <b-form-input
                v-model="trueWalletRegisterForm.keyId"
                :state="errors[0] ? false : null"
                :aria-invalid="false"
                type="text"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            name="loginToken"
            rules="required"
          >
            <b-form-group label="Login Token">
              <b-form-input
                v-model="trueWalletRegisterForm.loginToken"
                :state="errors[0] ? false : null"
                :aria-invalid="false"
                type="text"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            name="tmnId"
            rules="required"
          >
            <b-form-group label="Tmn ID">
              <b-form-input
                v-model="trueWalletRegisterForm.tmnId"
                :state="errors[0] ? false : null"
                :aria-invalid="false"
                type="text"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-button
            variant="success"
            type="submit"
            :disabled="isRegisteringTrueWallet"
          >
            {{
              $t('buttons.submit')
            }}
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      v-model="isShowOTPModal"
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      size="xl"
    >
      <validation-observer ref="verifyForm">
        <b-form @submit.prevent="onVerifyOTP">
          <validation-provider
            v-slot="{ errors }"
            name="reference"
            rules="required"
          >
            <b-form-group label="Reference code">
              <b-form-input
                v-model="trueWalletVerifyOTPForm.reference"
                :state="errors[0] ? false : null"
                :aria-invalid="false"
                placeholder=""
                type="text"
                readonly="readonly"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="otp"
            rules="required"
          >
            <b-form-group label="OTP">
              <b-form-input
                v-model.number="trueWalletVerifyOTPForm.otp"
                :state="errors[0] ? false : null"
                :aria-invalid="false"
                placeholder=""
                type="text"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-button
            variant="success"
            type="submit"
            :disabled="isVerifyingTrueWallet || isRegisteringTrueWallet"
          >
            {{
              $t('buttons.submit')
            }}
          </b-button>
          <b-button
            variant="outline-primary"
            type="button"
            class="ml-2"
            :disabled="isVerifyingTrueWallet || isRegisteringTrueWallet"
            @click="onRegister"
          >
            ส่งอีกครั้ง
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { required } from '@validations'
export default {
  props: {
    bankAccountDetail: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      required,
      trueWalletRegisterForm: {
        password: '',
        pin: '',
        keyId: '',
        loginToken: '',
        tmnId: '',
      },
      trueWalletVerifyOTPForm: {
        reference: '',
        otp: '',
      },
      isShowOTPModal: false,
    }
  },
  computed: {
    ...mapState({
      isRegisteringTrueWallet: (state) => state.user.isRegisteringTrueWallet,
      isVerifyingTrueWallet: (state) => state.user.isVerifyingTrueWallet,
      isRegisteringTrueWalletSuccess: (state) =>
        state.user.isRegisteringTrueWalletSuccess,
      isVerifyingTrueWalletSuccess: (state) =>
          state.user.isVerifyingTrueWalletSuccess,
      registerTrueWalletRef: (state) =>
          state.user.registerTrueWalletRef,
    }),
    isSuccess() {
      return !!this.bankAccountDetail?.trueWalletAccount
    },
  },
  watch: {
    registerTrueWalletRef(val) {
      this.trueWalletVerifyOTPForm.reference = val;
    },
  },
  mounted() {
    if(this.bankAccountDetail?.trueWalletAccount) {
      const trueWalletAccount = this.bankAccountDetail.trueWalletAccount
      this.trueWalletRegisterForm.password = trueWalletAccount.password
      this.trueWalletRegisterForm.pin = trueWalletAccount.pin
      this.trueWalletRegisterForm.keyId = trueWalletAccount.keyId
      this.trueWalletRegisterForm.tmnId = trueWalletAccount.tmnId
      this.trueWalletRegisterForm.loginToken = trueWalletAccount.loginToken
    }
  },
  methods: {
    ...mapActions(['verifyTrueWalletOTP', 'registerTrueWallet']),
    async onRegister() {
      this.$refs.registerForm.validate().then( async (success) => {
        if (success) {
          await this.registerTrueWallet({
            id: this.bankAccountDetail.id,
            form: this.trueWalletRegisterForm,
          })
          this.$bvModal.hide('verify-truewallet-account-register')
        }
      })
    },
    onVerifyOTP() {
      this.$refs.verifyForm.validate().then( async (success) => {
        if (success) {
          this.verifyTrueWalletOTP({
          id: this.bankAccountDetail.id,
          data: this.trueWalletVerifyOTPForm,
        })
        }
      })
    },
  },
}
</script>
